body {
  background-color: black !important;
}

.App {
  text-align: center;
  background-color: black;
}

.login-page-container {
  text-align: center;
  background-color: black;
}

.mainform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid;
  padding: 10px;
  box-shadow: 5px 10px; 
  background-color: gray;
}

.mainform input {
  width: 300px;
  height: 60px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hide {
  display: none;
}
.loginInput { 
  background-color: white;
  color: black;
  width: 250px;
  margin: 5px;
  height: 30px;
  border: 2px solid black;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.validate { 
  margin-top: 1%;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  width: 200px;
  height: 30px;
  color: black;
  background-color: white;
}

.validate:hover{
  color: #dc1b29;
  background-color: black;
  border: 1px solid #dc1b29;
  transition-duration: 200ms;
}

.forgot-btn {
  margin-top: .5%;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  width: 200px;
  height: 30px;
  color: white;
  background-color: transparent;
}

.forgot-btn:hover{
  text-decoration: underline;
}

.pswd-validate {
  margin-top: 2%;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  width: 200px;
  height: 30px;
  margin-left: 50%;
  transform: translate(-50%);
  color: white;
  background-color: black;
  text-transform: uppercase;
}

.pswd-validate:hover{
  color: black;
  background-color: white;
  border: 1px solid black;
  transition-duration: 200ms;
}

.login-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px !important;
  color: white;
  text-transform: uppercase;
  margin-bottom: 5.25px;
}

.login-underline{
  width: 145px;
  height: 3px;
  background-color: #a9abad;
  margin-right: auto;
  margin-left: auto;
}

.modal-title-pswd {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px !important;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5.25px;
}

.loginHeader {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.NewLogin {
  width: 50%;
  color: black;
}
.BenCol4 {
  background-color: black; 
  /* padding-top: 2%; */
  width: 120px; 
}
.BenCol4 p { 
  text-align: center;
  font-size: medium;
}

.modal-design {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 1%, rgba(61,0,0,1) 50%);
}
.incorrect-combo {
  border: 2px solid #dc1b29;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  /* width: 400px; */
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: black;
  color: white;
  text-transform: uppercase;
}

.loginStatus {
  border: 2px solid red;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  width: 400px;
  height: 50px;
  background-color: black;
  color: white;
}
@media only screen and (max-width: 414px) {
  .mainform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid;
    padding: 10px;
    box-shadow: 5px 10px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
  }

  .loginInput { 
    width: 250px;
  }

  .loginStatus {
    height: 4em;
    width: 200px;
  }
  .BenCol4 {
    width: 120px;
  }

  .validate{
    margin-top: 4%;
  }
}
