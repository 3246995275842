.exec-data-card-margin{
    margin: 0.75em;
}

.exec-data-flex-card{
    display: flex;
    width: 50%;
}

.exec-acct-card.ui.card{
    border-radius: 0;
    border: 2px solid #dc1b29;
    box-shadow: none;
}

.ui.card>.content>.exec-acct-card-header.header{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.exec-acct-data-table.ui.table{
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
}

.exec-acct-data-table.ui.table thead tr th{
    text-align: center;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.exec-data-tbl-label{
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.modal-edit-form-section{
    margin: 1.5em;
}

.modal-edit-form-section .ui.horizontal.divider{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: larger;
}

#mem-controls-title{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

#exec-renew-btn.ui.button{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0;
    border: 2px solid #a9abad;
    background-color: #a9abad;
    color: black;
    width: 300px;
    margin-top: 1em;
}
#exec-renew-btn.ui.button:hover{
    border: 2px solid black;
    color: black;
    background-color: white;
    transition-duration: 200ms;
}

#exec-delete-mem-btn.ui.button{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0;
    border: 2px solid #dc1b29;
    background-color: #dc1b29;
    color: white;
    width: 300px;
    margin-top: 1em;
}

#exec-delete-mem-btn.ui.button:hover{
    border: 2px solid red;
    color: red;
    background-color: white;
    transition-duration: 200ms;
}