.req-form{
    padding: 2em;
    margin-top: 1em;
    border: 2px solid black;
}

.req-form .col{
    height: 50px;
}

.req-form-label{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    text-align: right;
}

.req-form-select{
    border: 2px solid black;
    width: 300px;
    text-align: center;
}

.req-form-input{
    font-family: 'Open Sans' sans-serif;
    border: 2px solid black;
    padding: 5px;
    width: 300px;
}

.req-submit-container{
    text-align: center;
}

.req-form-submit{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: larger;
    text-transform: uppercase;
    color: white;
    background-color: black;
    border: 2px solid black;
}

.req-form-submit:hover{
    color: black;
    background-color: white;
    transition-duration: 200ms;
}

@media only screen and (max-width: 414px) {
    .req-form-select {
      width: 175px;
    }
    
    .req-form-input {
       width: 175px;
    }
}