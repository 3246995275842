#loginLogo {
  width: 60%;
  background: black;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1080px) {
  #loginLogo {
    width: 250px;
  }
}