.storefrontMain {
    background-color: black;
    width: 100%;
}

.searchMemberStorefront {
    background-color: white;
}

.storefront-section-title{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1em !important;
}

.search-box-storefront-container{
    text-align: center;
    margin-top: 1em;
}

.search-box-storefront{
    width: 250px;
    height: 40px;
    border: 2px solid black;
    font-family: 'Open Sans', sans-serif;
    padding: 5px;
}

.ui.card.storefront-search-results{
    border: 2px solid black;
    border-radius: 0;
}

.ui.card.storefront-search-results .header{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.ui.card.storefront-search-results .content{
    padding: 0;
}

.ui.card.storefront-search-results table{
    border-radius: 0;
    text-align: center ;
    font-family: 'Open Sans', sans-serif;
}

.ui.card.storefront-search-results table th{
    text-transform: uppercase;
}

.ui.button.member-info-btn-storefront{
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}

.ui.button.member-info-btn-storefront:hover{
    background-color: white;
    color: black;
}

.members-info-modal .header{
    text-align: center;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    text-transform: uppercase;
    border: none !important;
}

.members-info-modal .actions {
    background-color: white !important;
    border: none;
}

.members-info-modal .actions button {
    color: white !important;
    background-color: #dc1b29;
    border-radius: 0;
}

.members-info-modal .content h1 {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}

.members-info-modal .content .description{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin-bottom: 3em;
}

.members-info-modal .content .description h1{
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}

.members-info-modal .content .description h3 {
    font-family: 'Open Sans', sans-serif;
}

.members-info-modal .content .content{
    margin-bottom: 3em;
}

.members-info-modal .content .content table{
    border-radius: 0;
}

.renew-div{
    margin: auto;
    width: 100%;
    padding: 1%;
    border: 2px solid black;
    text-align: center;
}

.renew-div form .row .col{
    text-align: center;
}

.required-text{
    font-family: 'Open Sans', sans-serif;
    color: #dc1b29;
    font-size: small;
}

.store-renew-form-label{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    width: 100%;
    text-align: right;
    margin-top: 1em;
    margin-bottom: 1em;
}

.store-renew-form-input{
    font-family: 'Open Sans', sans-serif;
    color: black;
    border: 2px solid black;
    width: 350px;
    padding: 5px;
    margin-top: 1.5em;
}

.store-renew-form-input:disabled{
    color: gray;
}

.renew-div form input[type="submit"]{
    padding: 10px;
    margin: 1.25em;
    width: 250px;
    color: white;
    background-color: black;
    border: 1px solid black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.renew-div form input[type="submit"]:hover{
    color: black;
    background-color: white;
    transition-duration: 200ms;
}

@media only screen and (max-width: 414px) {
    .storefrontMain{
        background-color: white !important;
    }

    .store-renew-form-label{
        text-align: center;
        margin-bottom: 0;
    }

    .store-renew-form-input{
        width: 300px;
    }

    .renew-div form input[type="submit"]{
        margin-top: 2em;
    }
}