#req-card {
  height: 300px
}

#req-status {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: x-large;
}