.newMember {
  background-color: white;
  padding: 1em;
}

#register-modal {
  background-color: white;
  padding: 2em 1em 1em 1em;
  border: 2px solid black;
  margin-top: 1em;
}

#register-modal .col{
  height: 70px;
}

.new-member-storefront-label{
  font-family: "Open-Sans", sans-serif;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  width: 100%;
  text-align: right;
  /* margin: 1em; */
  font-size: 18px;
}

.required-asterisk{
  color: #dc1b29;
}

.new-member-storefront-input{
  color: black;
  font-family: 'Open Sans', sans-serif;
  border: 2px solid black;
  padding: 5px;
  /* margin: 1em; */
  width: 350px;
}

.new-member-submit-container{
  text-align: center;
}

.new-member-storefront-submit{
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: larger;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: 2px solid black;
  width: 250px;
  height: 50px;
}

.new-member-storefront-submit:hover{
  color: black;
  background-color: white;
  transition-duration: 200ms;
}

@media only screen and (max-width: 414px) {
  #register-modal .col {
      height: 50px;
    }
  
  .new-member-storefront-label{
    text-align: left;
  }
  
  .new-member-storefront-input{
    width: 300px
  }
}