.img-height{
  height: 200px;
}

.item-modal-market.ui.modal>.actions{
  background-color: white;
}

.item-modal-market.ui.modal>.actions .ui.icon.button{
    color: white;
    background-color: #dc1b29;
    border-radius: 0;
    border: 1px solid white;
}

.item-modal-market.ui.modal>.actions .ui.icon.button:hover{
  background-color: white;
  color:#dc1b29;
  border: 1px solid #dc1b29;
}

.item-modal-market.ui.modal>.header{
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}

.modal-edit-form.ui.form .row{
  margin-bottom: 1em;
}

.modal-edit-form.ui.form .field label {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.modal-edit-form.ui.form .inline.field>label{
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.modal-edit-form.ui.form .field select{
  border-radius: 0;
  border: 2px solid black;
}

.modal-edit-form.ui.form .inline.field .ui.input input{
  border: 2px solid black;
  border-radius: 0;
}

.modal-edit-form.ui.form textarea{
  border: 2px solid black;
  border-radius: 0;
}

.modal-edit-form.ui.form .field .ui.button{
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0;
  border: 2px solid white;
  background-color: black;
  color: white;
  width: 150px;
}

.modal-edit-form.ui.form .field .ui.button:hover{
  background-color: white;
  color: black;
  border: 2px solid black;
  transition-duration: 200ms;
}

.upload-wrapper{
  text-align: center;
}

.edit-img-wrapper{
  text-align: center;
  border: 2px solid black;
  margin-top: 1em;
  margin-bottom: 1em;
}

.admin-market-product-table.ui.celled.striped.table{
  border: 1px solid black;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Open-Sans', sans-serif;
  border-radius: 0;
}

.admin-market-product-table.ui.celled.striped.table th{
  text-transform: uppercase;
  text-align: left;
  background-color: black;
  color: white;
  border-radius: 0;
  font-size: 18px;
}

.admin-market-product-table.ui.celled.striped.table .product-table-label-row{
  font-weight: 700;
  border-radius: 0;
}

.admin-market-product-table.ui.celled.striped.table .ui.button{
  border-radius: 0;
  margin: 1em;
  min-width: 200px;
  border-radius: 0;
}

.admin-market-product-table.ui.celled.striped.table .ui.button.negative {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  border: 2px solid white
}

.admin-market-product-table.ui.celled.striped.table .ui.button.negative:hover{
  border: 2px solid red;
  color: red;
  background-color: white;
}