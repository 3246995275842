.profileMain { 
  background-color: black; 
  width: 100%;
}

.sign-out-profile-btn{
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
}

.sign-out-profile-btn a{
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.sign-out-profile-btn a:hover{
  text-decoration: none;
  color: black;
}


.section-titles {
  font-size: 25px !important;
  color: white;
  background-color: black;
  text-transform: uppercase;
}

.section-background{
  background-color: white;
}

.personal-info-container {
  padding: 1em;
  font-family: 'Open Sans', sans-serif;
}

.personal-info-container .row{
  margin-bottom: 1em;
}

.personal-info-label-col{
  text-align: right;
  font-weight: 700;
}

.personal-info-col{
  text-align: left;
  font-weight: 400;
}

.personal-info-container .row .col div {
  padding-bottom: 1em;
}

.customer-name {
  text-align: center;
  color: #dc1b29;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.profile-info-underline{
  height: 5px;
  background-color: #a9abad;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
}

h3.section-titles {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.market-items-container {
  margin-top: 1em;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  max-height: 550px;
  border: 2px solid black;
} 

.card-catalog-container {
  background-color: black;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  border: 2px solid black;
  margin: 1em;
  width: 400px;
  text-align: center;
  border: 2px solid #dc1b29;
}

.item-img {
  height: 200px;
  margin: 1em;
  background-color: white;
  border: 2px solid #dc1b29;
}

.edit-profile-modal .actions {
  background-color: white !important;
  border: none;
}

.edit-profile-modal .actions button {
  color: white !important;
  background-color: #dc1b29;
  border-radius: 0;
}

.edit-modal-header{
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
  border: none !important;
}

.edit-profile-modal .content div{
  margin: 2em;
}

.edit-profile-modal .content label{
  width: 100px;
  margin-right: 1em;
  text-align: right;
}

.edit-profile-modal-input{
  height: 30px;
  width: 300px;
  margin-bottom: 5px;
  border-radius: 0px;
  border: 2px solid black;
}

.edit-modal-field-label{
  font-family: 'Open Sans', sans-serif !important;
  text-transform: uppercase !important;
  font-weight: 700;
}

.edit-modal-field-submit{
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0px;
  border: 1px solid black;
  background-color: black;
  color: white;
  margin-left: 1em;
  width: 180px;
  height: 30px;
}

.edit-modal-field-submit:hover{
  color: black;
  background-color: white;
  transition-duration: 200ms;
  border: 1px solid black;
}

.edit-profile-modal .content div form{
  display: inline;
}

.request-container {
  padding: 1em;
  margin-top: 1em;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid black;
  font-family: 'Open Sans', sans-serif;
}

.request-style-header{
  color: #dc1b29;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 14pt;
  font-weight: 700;
}

.request-style {
  color: black;
  text-align: center;
}

.request-scroll::-webkit-scrollbar {
  display: none;
}

.request-scroll {
  overflow: scroll;
  width: 100%;
  padding: 10px;
}

.request-item-row {
  padding: 10px;
}

.message-underline {
  width: 100%;
  height: 1px;
  background-color: #dc1b29;
}

.item-details{
  margin-bottom: 3%;
}

.request-item-btn{
  text-transform: uppercase;
  color: #a9abad;
  background-color: black;
  border: 2px solid #a9abad;
  margin-bottom: 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.request-item-btn:hover{
  color: white;
  border: 2px solid white;
  transition-duration: 200ms;
}

@media only screen and (max-width: 360px) {
  .request-col-hide-sm{
    display: none;
  }
}

@media only screen and (max-width: 414px) {

  .profileMain {
    background-color: white !important;
  }

  .edit-profile-modal{
    width: 95% !important;
  }

  .edit-profile-modal-input{
    width: 240px;
   }

  .edit-profile-modal .content label{
    text-align: center;
    margin-right: 0;
  }

  .edit-modal-field-submit{
    margin-left: 0;
    margin-top: 1em;
    margin-bottom: 3em;
    width: 150px;
  }

  .request-style{
    font-size: 12.5px;
  }
}

@media only screen and (max-width: 768px){
  .request-col-hide-md{
    display: none;
  }
}


