.alert-div {
  color:white;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: large;
}

.adminMain {
  background-color: black;
  width: 100%;
}

/* SIDE BAR NAVIGATION */
.navigation-container {
  background-color: black;
  color: white;
  border-bottom: 5px solid #dc1b29;
}

.nav-list {
  text-align: center;
}

.nav-list.ui.list>.item.menu-item{
  display: inline-block;
  margin: 1em;
  padding: 0.5em;
  font-family: 'Open Sans', sans-serif;
}

.menu-item {
  border: 2px solid white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  width: 125px;
}

.menu-item:hover{
  cursor: pointer;
  color: #dc1b29;
  border: 2px solid #dc1b29;
}

.menu-item-active{
  color: #dc1b29;
  border: 2px solid #dc1b29;
}

.dash-title-container{
  height: 100px;
  margin-bottom: 1em;
  padding-top: 1em;
}

.dash-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  color: black;
  text-align: center;
  margin-bottom: 0;
  height: 50px;
}

.dash-title-underline{
  height: 5px;
  width: 300px;
  background-color: #a9abad;
  margin-left: auto;
  margin-right: auto;
}

.side-dash-nav {
  border-right: 3px solid #dc1b29;
  text-align: center;
}

.side-dash-btn {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #dc1b29;
  color: white;
  border: 2px solid black;
  height: 30px;
  width: 160px;
  margin-top: 1em;
}

.side-dash-btn:hover {
  color: black;
  background-color: white;
}

/* DASH CONTENT */
.dash-content {
  margin: auto;
  justify-content: center;
  border: 2px solid #dc1b29;
  padding: 1em;
}

.card-list-col {
  border: 2px solid black;
  padding-top: 1em;
}

.dash-list-container{
  overflow: scroll;
  height: 680px;
}

.dash-list-container::-webkit-scrollbar {
  display: none;
}

.dnl-btn-container {
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
}

.dnl-btn {
  min-width: 200px;
  padding: 0.55em;
  color: white;
  background-color: black;
  border: 2px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
}

.dnl-btn:hover{
  color: black;
  background-color: white;
  border: 2px solid black;
}

.dash-search-container{
  text-align: center;
}

.dash-search{
  margin-top: 1em;
  margin-bottom: 1em;
  width: 225px;
  padding: 5px;
}

.dash-card.ui.card {
  height: 250px;
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 0;
  border: 2px solid black;
  box-shadow: none;
}

.dash-card.ui.card .content{
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 0;
}

.dash-card-title{
  text-transform: uppercase;
  color: #a9abad;
  font-family: 'Open Sans',sans-serif;
  font-weight: 700;
  border-bottom: 2px solid #dc1b29;
}

.dash-card-title button{
  color: #dc1b29;
  border: none;
  background-color: black;
  font-family: Open Sans,sans-serif;
  font-weight: 700;
}

.dash-card-title button:hover{
  color: white;
  text-decoration: underline;
}

.dash-card-info-label{
  text-transform: uppercase;
  color: #dc1b29;
  font-weight: 700;
}

.dash-card.ui.card .description{
  padding-bottom: 0.25em;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.dash-card-btn-row{
  margin: 1em;
}

.ui.button.dash-card-btn{
  max-width: 200px;
  border-radius: 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  background-color: white;
  color: black;
  border: 2px solid black;
}

.dash-card-select{
  max-width: 200px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-align: center;
}

.ui.button.dash-card-btn:hover{
  background-color: black;
  color:#dc1b29;
  border: 2px solid #dc1b29;
  transition-duration: 200ms;
}

.dash-card .content .initial-contact-section .header{
  margin-top: 2%;
  text-align: center;
  font-size: 17px;
}

.dash-card .content .initial-contact-section .button{
  width: 100%;
  margin-top: 25px;
}

.ra-title {
  color: black;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.title-num {
  color: #dc1b29;
}

.recent-activity{
  margin: 1em;
  border: 2px solid black;
  padding-top: 1em;
}

.recent-activity-container{
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  max-height: 500px;
}

.recent-activity-container .event{
  border-top: 2px solid #a9abad;
  /* border-bottom: 2px solid #a9abad; */
  margin-top: 0.5em;
}

.recent-activity-container .event .content .x.icon:hover{
  cursor: pointer;
  color: black;
}

.chat-line {
  height: 2px;
  background-color: #dc1b29;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
}

.tab-content{
  background-color: white;
  color: black;
  display: block;
  min-height: 920px;
}