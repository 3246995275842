.header-banner {
  background-color: #dc1b29;
  background-image: url(./images/EAC_TopBannerBG_V02.png);
  height: 150px;
}

.header-col-left{
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.header-logo-container {
  margin: auto;
  text-align: center;
}

.header-logo{
  width: 70%;
}

.header-drpdwn-container{
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.dropdown {
  position: relative;
  float: right;
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #121212;
}

.dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  color: white;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.btn-drpdwn{
  color: white !important;
  background-color: black !important;
  border: none !important;
  padding: 0.5em !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.btn-drpdwn:hover{
  background-color: black !important;
  color: #dc1b29 !important;
}

.dropdown-content a {
  color: white;
  background-color: black;
  padding: 0.5em;
  text-decoration: none;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.dropdown-content a:hover {
  background-color: black;
  color: #dc1b29;
}

.report-issue-modal .actions{
  background-color: white !important;
  border: none;
}

.report-issue-modal .actions button{
  color: white !important;
  background-color: #dc1b29;
  border-radius: 0;
}

.report-issue-modal .header{
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border: none !important;
}

.modal-header-underline{
  width: 200px;
  height: 6px;
  background-color: #a9abad;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.5em !important;
}

.report-issue-form{
  text-align: center;
}
.report-issue-form input{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  color: black;
  border: 2px solid black;
  width: 60%;
  height: 35px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.report-issue-form textarea{
  color: black;
  border: 2px solid black;
  height: 120px;
  width: 60%;
  margin-bottom: 2em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.report-issue-form input[type="submit"]{
  color: white;
  background-color: black;
  border: 1px solid black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  height: 40px;
  width: 150px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.report-issue-form input[type="submit"]:hover{
  color: black;
  background-color: white;
  transition-duration: 200ms;
}

.reset-pswd-modal .actions {
  background-color: white !important;
  border: none;
}

.reset-pswd-modal .actions button {
  color: white !important;
  background-color: #dc1b29;
  border-radius: 0;
}

.reset-pswd-modal .header {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border: none !important;
  padding-bottom: 0 !important;
}

.reset-modal-desc{
  text-align: center;
}

.reset-pswd-label{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    width: 100%;
    text-align: right;
    margin-top: 1em;
    margin-bottom: 1em;
}

.reset-pswd-input{
  font-family: 'Open Sans', sans-serif;
  color: black;
  border: 2px solid black;
  width: 200px;
  margin-right: 50%;
  padding: 5px;
  margin-top: 1.5em;
}

.reset-modal-desc form input[type="submit"] {
  padding: 10px;
  margin: 1.25em;
  width: 250px;
  color: white;
  background-color: black;
  border: 1px solid black;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.reset-modal-desc form input[type="submit"]:hover {
  color: black;
  background-color: white;
  transition-duration: 200ms;
}

ul {
  list-style-type: none;
  /* margin: 10px; */
  padding: 0;
  overflow: hidden;
  justify-content: center;
  margin: auto;
  /* background-color: #333333; */
  width: 100%;
  /* background:red; */
  /* height: 20px; */
  text-align: center;
}

li {
  /* float: left; */
  text-align: center;
  margin-right: 0;
  display: inline-block;
  *display: inline; /*IE7*/
  *zoom: 1; /*IE7*/
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 10px;
  text-decoration: none;
}

li a:hover {
  background-color: #f3f3fc;
  color: black;
  border-radius: 5px;
  height: 50px;
}

.logoutNow {
  width: 100px;
  background-color: red;
  color: white;
  height: 50px;
  border: 2px solid gray;
  border-radius: 5px;
  margin-top: -3%;
  position: absolute;
}

@media only screen and (max-width: 414px) {
  .header-col-left{
    display: none;
  }

  .header-logo{
    width: 100%;
  }

  .dropdown{
    text-align: center;
    float: none;
  }

  .report-issue-modal{
    height: 80% !important;
    width: 95% !important;
  }

  .reset-pswd-modal{
    height: 50% !important;
    width: 75% !important;
  }

  .reset-pswd-label {
    text-align: center;
  }

  .reset-pswd-input{
    margin-right: 0;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  .report-issue-form input, .report-issue-form textarea {
    width: 70%;
  }

}
