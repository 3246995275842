.login-title-emphasis a{
    color: #dc1b29;
}

.login-title-emphasis a:hover{
    color: #dc1b29;
    text-decoration: underline;
}

.footer-text {
    font-size: 10px;
    background-color: black;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    color: white;
}