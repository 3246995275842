.underline {
  height: 5px;
  background-color: #dc1b29;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0 !important;
}

.titleDiv h1 {
  color: white;
  font-family: "Open Sans", sans-serif !important;
}

.titleDiv {
  padding-bottom: 1%;
  padding-left: 10%;
  padding-right: 10%;
  font-family: "Open Sans", sans-serif;
}

.LoginContainer {
  margin-top: 3%;
  background-color: black;
}

.login-title-emphasis {
  color: #dc1b29;
}

@media only screen and (max-width: 414px) {
  .titleDiv h1 {
    font-size: 20px !important;
  }

  .LoginContainer{
    margin-bottom: 5%;
  }
}
